import { createRouter, createWebHashHistory } from "vue-router";
import open from "@/layout/open";
import screen from "@/layout/screen";
import platform from "@/layout/platform";
import emptyPrint from "@/layout/emptyPrint";

const openRoutes = [
  {
    path: "/login",
    name: "login",
    redirect: "/login",
    component: open,
    meta: {
      title: "登录",
      classify: "login",
      icon: "login",
      affix: true,
      isShow: 0,
      table: "",
    },
    children: [
      {
        path: "/login",
        component: () => import("@/views/login/index"),
        name: "login",
        meta: {
          title: "登录",
          classify: "login",
          icon: "login",
          affix: true,
          isShow: 0,
          table: "",
        },
      },
    ],
  },
  {
    path: "/regist",
    name: "regist",
    redirect: "/regist",
    component: open,
    meta: {
      title: "注册",
      classify: "regist",
      icon: "regist",
      affix: true,
      isShow: 0,
      table: "",
    },
    children: [
      {
        path: "/regist",
        component: () => import("@/views/regist/index"),
        name: "regist",
        meta: {
          title: "注册-蓝风铃",
          classify: "regist",
          icon: "regist",
          affix: true,
          isShow: 0,
          table: "",
        },
      },
    ],
  },
  {
    path: "/findpwd",
    name: "findpwd",
    redirect: "/findpwd",
    component: open,
    meta: {
      title: "找回密码",
      classify: "findpwd",
      icon: "findpwd",
      affix: true,
      isShow: 0,
      table: "",
    },
    children: [
      {
        path: "/findpwd",
        component: () => import("@/views/findpwd/index"),
        name: "findpwd",
        meta: {
          title: "找回密码-蓝风铃",
          classify: "findpwd",
          icon: "findpwd",
          affix: true,
          isShow: 0,
          table: "",
        },
      },
    ],
  },
  {
    path: "/print",
    component: emptyPrint,
    redirect: "/print",
    name: "print",
    meta: {
      title: "打印详情",
      classify: "print",
      icon: "print",
      affix: true,
      isShow: 0,
    },
    children: [
      {
        path: "/print/:id",
        component: () => import("@/views/confirmation/print"),
        name: "print",
        meta: {
          title: "打印详情",
          classify: "print",
          icon: "print",
          affix: true,
          isShow: 0,
        },
      },
    ],
  },
];
const homeRoutes = filterByKey([
  {
    path: "/",
    component: platform,
    redirect: "/home",
    name: "home",
    meta: {
      title: "首页",
      classify: "home",
      icon: "home",
      affix: true,
      level: 5,
      isShow: 1,
      authClassify: "home",
      table: "",
    },
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index"),
        name: "homeIndex",
        meta: {
          title: "首页",
          classify: "home",
          icon: "home",
          affix: true,
          level: 5,
          isShow: 1,
          authClassify: "home",
          table: "",
        },
      },
    ],
  },
]);

const clientCheckRoutes = filterByKey([
  {
    path: "/clientCheck",
    component: platform,
    redirect: "/clientCheck/index",
    name: "clientCheck",
    meta: {
      title: "客户信息",
      classify: "clientCheck",
      icon: "clientCheck",
      affix: true,
      role: 4,
      level: 5,
      isShow: 1,
      authClassify: "clientCheck",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/clientCheck/index"),
        name: "clientCheckIndex",
        meta: {
          title: "客户信息",
          classify: "clientCheck",
          icon: "clientCheck",
          affix: true,
          role: 4,
          level: 5,
          isShow: 1,
          authClassify: "clientCheck",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/clientCheck/detail"),
        name: "clientCheckTable",
        meta: {
          title: "客户信息",
          classify: "clientCheck",
          icon: "clientCheck",
          affix: true,
          role: 4,
          level: 5,
          isShow: 1,
          authClassify: "clientCheck",
        },
      },
    ]),
  },
]);
const classifyRoutes = filterByKey([
  {
    path: "/classify",
    component: platform,
    redirect: "/classify/index",
    name: "classify",
    meta: {
      title: "课程管理",
      classify: "classify",
      icon: "classify",
      affix: true,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "classify",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/classify/index"),
        name: "classifyIndex",
        meta: {
          title: "课程管理",
          classify: "classify",
          icon: "classify",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "classify",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/classify/detail"),
        name: "classifyDetail",
        meta: {
          title: "课程管理详情",
          classify: "classify",
          icon: "classify",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "classify",
        },
      },
    ]),
  },
  {
    path: "/classifyInfo",
    component: platform,
    redirect: "/classifyInfo/index",
    name: "classifyInfo",
    meta: {
      title: "课程信息",
      classify: "classifyInfo",
      icon: "classifyInfo",
      affix: true,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "classifyInfo",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/classifyInfo/index"),
        name: "classifyInfoIndex",
        meta: {
          title: "课程信息",
          classify: "classifyInfo",
          icon: "classifyInfo",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "classifyInfo",
        },
      },
    ]),
  },
]);
const customerRoutes = filterByKey([
  {
    path: "/customer",
    component: platform,
    redirect: "/customer/index",
    name: "customer",
    meta: {
      title: "客户管理",
      classify: "customer",
      icon: "customer",
      affix: true,
      role: 4,
      level: 5,
      isShow: 1,
      authClassify: "customer",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/customer/index"),
        name: "customerIndex",
        meta: {
          title: "客户管理",
          classify: "customer",
          icon: "customer",
          affix: true,
          role: 4,
          level: 5,
          isShow: 1,
          authClassify: "customer",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/customer/detail"),
        name: "customerTable",
        meta: {
          title: "客户管理",
          classify: "customer",
          icon: "customer",
          affix: true,
          role: 4,
          level: 5,
          isShow: 1,
          authClassify: "customer",
        },
      },
    ]),
  },{
        path: "/customerApply",
        component: platform,
        redirect: "/customerApply/index",
        name: "customerApply",
        meta: {
            title: "申请管理",
            classify: "customerApply",
            icon: "customerApply",
            affix: true,
            role: 4,
            level: 5,
            isShow: 1,
            authClassify: "customerApply",
        },
        children: filterByKey([
            {
                path: "index",
                component: () => import("@/views/customerApply/index"),
                name: "customerApplyIndex",
                meta: {
                    title: "申请管理",
                    classify: "customerApply",
                    icon: "customerApply",
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "customerApply",
                },
            },
            {
                path: "detail/:id",
                component: () => import("@/views/customerApply/detail"),
                name: "customerApplyTable",
                meta: {
                    title: "客户管理",
                    classify: "customerApply",
                    icon: "customerApply",
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "customerApply",
                },
            },
        ]),
    },
]);

const confirmationRoutes = filterByKey([
  {
    path: "/confirmation",
    component: platform,
    redirect: "/confirmation/index",
    name: "confirmation",
    meta: {
      title: "签到管理",
      classify: "confirmation",
      icon: "confirmation",
      affix: true,
      role: 4,
      level: 5,
      isShow: 1,
      authClassify: "confirmation",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/confirmation/index"),
        name: "confirmationIndex",
        meta: {
          title: "签到管理",
          classify: "confirmation",
          icon: "confirmation",
          affix: true,
          role: 4,
          level: 5,
          isShow: 1,
          authClassify: "confirmation",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/confirmation/detail"),
        name: "confirmationTable",
        meta: {
          title: "签到管理",
          classify: "confirmation",
          icon: "confirmation",
          affix: true,
          role: 4,
          level: 5,
          isShow: 1,
          authClassify: "confirmation",
        },
      }
    ]),
  },
]);

const importRoutes = filterByKey([
  {
    path: "/import",
    component: platform,
    redirect: "/import",
    name: "import",
    meta: {
      title: "数据导入",
      classify: "import",
      icon: "import",
      affix: true,
      level: 5,
      isShow: 1,
      authClassify: "import",
      table: "",
    },
    children: [
      {
        path: "/import",
        component: () => import("@/views/import/index"),
        name: "printIndex",
        meta: {
          title: "数据导入",
          classify: "import",
          icon: "import",
          affix: true,
          level: 5,
          isShow: 1,
          authClassify: "import",
          table: "",
        },
      },
      {
        path: "/upload/:type",
        component: () => import("@/views/import/upload"),
        name: "uploadIndex",
        meta: {
          title: "导入数据",
          classify: "print",
          icon: "print",
          affix: true,
          level: 5,
          isShow: 1,
          authClassify: "print",
          table: "",
        },
      },
    ],
  },
]);

const systemRoutes = filterByKey([
  {
    path: "/boss",
    component: platform,
    redirect: "/boss/index",
    name: "boss",
    meta: {
      title: "平台管理员",
      classify: "boss",
      icon: "boss",
      affix: true,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "boss",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/boss/index"),
        name: "bossIndex",
        meta: {
          title: "平台管理员",
          classify: "boss",
          icon: "boss",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "boss",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/boss/detail"),
        name: "bossDetail",
        meta: {
          title: "平台管理员详情",
          classify: "boss",
          icon: "boss",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "boss",
        },
      },
    ]),
  },
  {
    path: "/auth",
    component: platform,
    redirect: "/auth/index/0",
    name: "auth",
    meta: {
      title: "授权管理",
      classify: "auth",
      icon: "auth",
      affix: true,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "auth",
    },
    children: filterByKey([
      {
        path: "index/:id",
        component: () => import("@/views/auth/index"),
        name: "authIndex",
        meta: {
          title: "授权管理",
          classify: "auth",
          icon: "auth",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "auth",
        },
      },
      {
        path: "options",
        component: () => import("@/views/auth/options"),
        name: "authOptions",
        meta: {
          title: "分类选项管理",
          classify: "auth",
          icon: "auth",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "auth",
        },
      },
    ]),
  },
  {
    path: "/system",
    component: platform,
    redirect: "/system/index",
    name: "system",
    meta: {
      title: "系统配置",
      classify: "system",
      icon: "system",
      affix: true,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "system",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/system/index"),
        name: "systemIndex",
        meta: {
          title: "系统配置",
          classify: "system",
          icon: "system",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "system",
        },
      },
    ]),
  },
]);

const constantRoutes = [
  ...openRoutes,
  ...homeRoutes,
  ...clientCheckRoutes,
  ...classifyRoutes,
  ...customerRoutes,
  ...confirmationRoutes,
  ...importRoutes,
  ...systemRoutes,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

// TODO: 先实现下一级的过滤，
function filterByKey(arrayObj) {
  const index = arrayObj.findIndex((item) => {
    return item.meta.isShow === 0;
  });
  if (index > -1) {
    arrayObj.splice(index, 1);
  }
  return arrayObj;
}

export {
  homeRoutes,
  clientCheckRoutes,
  classifyRoutes,
  customerRoutes,
  confirmationRoutes,
  importRoutes,
  systemRoutes,
  router,
};
