<template>
  <div class="sidebar" id="sidebar">
    <div class="site-name" data-path="/" @click="_jump">
      {{ systemConfig.name }}
    </div>

    <template v-if="homeRoutes.length">
      <ul class="main-nav">
        <li v-for="(item, index) in homeRoutes" :key="index">
          <router-link :to="{ name: item.name }">{{
            item.meta.title
          }}</router-link>
        </li>
      </ul>
    </template>
    <template v-if="clientCheckRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in clientCheckRoutes" :key="index">
          <router-link :to="{ name: item.name }">{{
            item.meta.title
          }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="classifyRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in classifyRoutes" :key="index">
          <router-link :to="{ name: item.name }">{{
            item.meta.title
          }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="customerRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in customerRoutes" :key="index">
          <router-link :to="{ name: item.name }">{{
            item.meta.title
          }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="confirmationRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in confirmationRoutes" :key="index">
          <router-link :to="{ name: item.name }">{{
            item.meta.title
          }}</router-link>
        </li>
      </ul>
    </template>

     <template v-if="importRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in importRoutes" :key="index">
          <router-link :to="{ name: item.name }">{{
            item.meta.title
          }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="systemRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in systemRoutes" :key="index">
          <router-link :to="{ name: item.name }">{{
            item.meta.title
          }}</router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import {
  homeRoutes,
  clientCheckRoutes,
  classifyRoutes,
  customerRoutes,
  confirmationRoutes,
  importRoutes,
  systemRoutes
} from '../../router'
import { _data, _methods, util } from '../../common/public'

export default {
  name: 'PageSidebar',
  data () {
    return {
      ..._data,
      routes: [],
      homeRoutes: [], // homeRoutes
      clientCheckRoutes: [], // clientCheckRoutes
      classifyRoutes: [], // classifyRoutes
      customerRoutes: [], // customerRoutes
      confirmationRoutes: [], // confirmationRoutes
      importRoutes: [], // importRoutes
      systemRoutes: [], // systemRoutes
      curRouteName: '',
      systemConfig: {},
      userInfo: { level: 1 },
      /* -------------权限设置----------------------------- */
      auth: [],
      auth_classify: [],
      auth_array: [],
      auth_classify_array: []
      /* ------------------权限设置------------------------ */
    }
  },
  created () {
    console.log('this.$route', this.$route)
    this.curRouteName = this.$route.name
    this.systemConfig = util.getLocalStorage('systemConfig', {})
    console.log('dd', this.systemConfig)
    this.userInfo = util.getLocalStorage('userInfo', {})
    this.processData()
  },
  methods: {
    ..._methods,
    /*
     * 权限控制
     * **/
    processData () {
      this.auth = this.userInfo.u_auth ? JSON.parse(this.userInfo.u_auth) : []
      this.auth_classify = this.userInfo.u_auth_classify
        ? JSON.parse(this.userInfo.u_auth_classify)
        : []
      console.log('this.auth_classify', this.auth_classify)
      console.log('this.auth', this.auth)
      this.auth_array = [] // 前端路由
      for (const item of this.auth) {
        this.auth_array.push(item.routeClient)
      }
      this.auth_classify_array = [] // 前端类名
      for (const item of this.auth_classify) {
        this.auth_classify_array.push(item.alias)
      }
      this.homeRoutes = this.moveItem(homeRoutes)
      this.clientCheckRoutes = this.moveItem(clientCheckRoutes)
      this.classifyRoutes = this.moveItem(classifyRoutes)
      this.customerRoutes = this.moveItem(customerRoutes)
      this.confirmationRoutes = this.moveItem(confirmationRoutes)
      this.importRoutes = this.moveItem(importRoutes)
      this.systemRoutes = this.moveItem(systemRoutes)
    },
    moveItem (arr) {
      if (this.userInfo.u_role - 0 < 3) {
        return arr
      }
      const _array = util.extend(true, [], arr)
      for (let i = _array.length - 1; i >= 0; i--) {
        if (_array[i].meta.classify === 'home') {
          continue
        }
        const authClassify = _array[i].meta.authClassify || ''
        if (this.auth_classify_array.indexOf(authClassify) === -1) {
          _array.splice(i, 1)
        } else {
          if (_array[i].children.length) {
            for (let j = _array[i].children.length - 1; j >= 0; j--) {
              const authClassifyItem =
                _array[i].children[j].meta.authClassify || ''
              if (this.auth_classify_array.indexOf(authClassifyItem) === -1) {
                _array[i].children.splice(j, 1)
              }
            }
          }
        }
      }
      return _array
    },

    _jump (e) {
      const { path } = e.target.dataset
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 200px;
  background: #47ccf2;
  color: #fff;
  font-size: 16px;
  overflow-y: auto;
}

.site-name {
  height: 70px;
  font-size: 26px;
  padding-left: 24px;
  margin-bottom: 8px;
  border-bottom: #2bbee8 solid 1px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.sidebar {
  .sub-title {
    padding: 0 14px 10px;
    line-height: 1.5;
    border-left: #09addc solid 2px;
    font-size: 18px;
  }

  .sub-title .small {
    font-size: 13px;
  }

  .hr {
    margin-top: 6px;
    margin-bottom: 6px;
    border-bottom: #2bbee8 solid 1px;
  }

  a {
    font-size: 16px;
    color: #fff;
    display: block;
    line-height: 38px;
    padding: 0 20px;
    border-left: transparent solid 4px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s;
    text-align: left;

    &:hover {
      background: #0cbff3;
    }
  }
}

.sidebar a.router-link-active {
  background: #0cbff3;
  border-left-color: #09addc;
}

.sidebar a.router-link-active:hover {
  background: #0cbff3;
}

.selectYear {
  padding: 8px;
  margin-bottom: 8px;
}
</style>
