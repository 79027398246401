/*
 * @description: 公用的 data 和 methods v0.01
 * @author: https://www.51xuediannao.com/
 * @update: 2020-02-28 18:02
 */
import { util } from './util.js'
import { _OPTIONS } from './options.js'
import { _CONFIG } from '../config/config.js'
import { _rules } from './rules.js'
import Msg from '../js-lib/coco-message/message.js'
import defaultImage from '@/images/back-image.jpeg'
import defaultImageScreen from '@/images/screen.jpeg'
import defaultImageExcel from '@/images/excel.png'

const _data = {
  _CONFIG: _CONFIG,
  showPop: false,
  showNoData: false,
  listDataConfig: {
    params: {
      pageSize: 15,
      page: 1
    }
  },
  paginationLayout: 'prev, pager, next,total',
  listServices: null,
  listData: [],
  dates: [],
  groupsData: null,
  pageCount: 1,
  dataListCount: 0,
  loadingView: '',
  loading: false,
  loadingPages: true,
  loadMoreLoading: true,
  dataInit: true,
  pageEnd: false,
  isLoad: true,
  Loading: false,
  uploadData: { uploadTo: 'ali', uploadDir: 'images' },
  uploadAction: `${_CONFIG.host}/plug-in/action_webuploader/uploadadmin.php`,
  uploadHeaders: {
    Authorization: localStorage.getItem('TOKEN') || 'head'
  },
  Msg: Msg,
  defaultImage: defaultImage,
  defaultImageScreen: defaultImageScreen,
  defaultImageExcel: defaultImageExcel,
  defaultImageShow: false,
  NetworkImage: '',
  dot: '---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------'
}

const _methods = {
  init() {
    this._OPTIONS = _OPTIONS;

    this.userInfo = localStorage.getItem("UserInfo")
      ? {}
      : JSON.parse(localStorage.getItem("UserInfo"));
    this.curRoute = this.getCurRoute();
  },
  getApp(key) {
    const obj = this.GlobalData;
    if (key && typeof obj[key] === "undefined") {
      return null;
    }
    return key ? obj[key] : obj;
  },
  setApp(obj = {}) {
    this.GlobalData = util._extends(true, this.GlobalData, obj);
    return this;
  },

  uploadInit(upConfig) {
    this.uploadData = upConfig.uploadData || {
      uploadTo: "local",
      uploadDir: "images",
    };
    this.uploadAction =
      upConfig.uploadAction ||
      `${_CONFIG.host}/plug-in/action_webuploader/uploadadmin.php`;
    this.uploadHeaders = {
      Authorization: localStorage.getItem("TOKEN") || "head",
    };
  },

  getRules(requiredFields) {
    const requiredFieldsArray = requiredFields.split(",");
    const requiredRules = [];
    for (let i = 0; i < requiredFieldsArray.length; i++) {
      const _obj = _rules.find((m) => {
        return m.name === requiredFieldsArray[i];
      });
      typeof _obj !== "undefined" && requiredRules.push(_obj);
    }
    return requiredRules;
  },
  getRequiredFields() {
    const requiredEls = document.querySelectorAll("[required]");
    const names = [];
    for (const item of requiredEls) {
      !item.getAttribute("data-not") && names.push(item.getAttribute("name"));
    }
    return names.join(",");
  },
  getRulesDiff(names, rules) {
    const _rules = rules || this.rules || [];
    const _names = typeof names === "string" ? names.split(",") : names;
    const arrayError = [];
    console.log("测试规则是否存在：\n");
    for (const item of _names) {
      const _rule = _rules.find((m) => {
        return item === m.name;
      });
      if (_rule) {
        const _rule_string = JSON.stringify(_rule);
        console.info(`name：${item}->rule：${_rule_string}`);
      } else {
        const item_string = item || "null";
        console.error(`name：${item_string}->rule：undefined`);
        arrayError.push(item);
      }
    }
    console.log(arrayError.join(","));
  },
  submitFormBefore() {
    // 最后一次拦截
    return true;
  },
  submitFormAfter() {},

  getCurRoute() {
    return this.$route;
  },

  cdnFilePath(path, defaultPath = "") {
    const cdn = _CONFIG.cdn || "";

    const _path = path || defaultPath;
    if (!_path) {
      return "";
    }
    if (_path.indexOf("//") > -1) {
      return _path;
    }

    return `${cdn}/${_path}`;
  },

  pageBottomEvents(obj) {
    console.log("pageBottomButtons", obj);
    const value = obj.value;
    const eventName =
      "pageBottomEvent" + value.charAt(0).toUpperCase() + value.slice(1);
    if (typeof eventName === "undefined") {
      console.error(`找不到 ${eventName} 这个方法`);
      return;
    }
    this[eventName](obj);
  },
  formatYmd(date = "") {
    if (!date) {
      return "";
    }
    return util.formatDate("YYYY-MM-DD hh:mm", date);
  },
  formatYmdHis() {
    var currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 月份从0开始，需要加1
    const day = currentDate.getDate();
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();
    let months = month < 10 ? "0" + month : month;
    let days = day < 10 ? "0" + day : day;
    let hourss = hours < 10 ? "0" + hours : hours;
    let minutess = minutes < 10 ? "0" + minutes : minutes;
    let secondss = seconds < 10 ? "0" + seconds : seconds;
    return (
      year +
      "-" +
      months +
      "-" +
      days +
      " " +
      hourss +
      ":" +
      minutess +
      ":" +
      secondss
    );
  },

  formatOption(value, optionName) {
    if (typeof _OPTIONS[optionName] === "undefined") {
      return "";
    }
    const obj = _OPTIONS[optionName].find((item) => {
      return item.value == value;
    });
    return obj ? obj.label || obj.name : "";
  },
  formattingYMD(value) {
    const date = new Date(value);
    const Y = date.getFullYear();
    const M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    // return Y + "-" + M + "-" + D;改value-format没用，我也不知道为什么
    // 最后传给后台的格式YYYYMMDD
    return Y + "-" + M + "-" + D;
  },
  formattingData(value) {
    if (value === undefined || value == null) {
      return "";
    }
    if (
      value === "" ||
      value.indexOf("0001-01-01") > -1 ||
      value.indexOf("0000-00-00") > -1
    ) {
      return "";
    }
    if (!value || value.indexOf("0000:") > -1 || value.indexOf("1000:") > -1) {
      return "";
    }

    const date = new Date(value);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
   
    const FormattedDateTime =
      year +
      "-" +
      month +
      "-" +
      day;
    return FormattedDateTime;
  },
  formattingTime(value) {
    if (value === undefined || value == null) {
      return "";
    }
    if (
      value === "" ||
      value.indexOf("0001-01-01") > -1 ||
      value.indexOf("0000-00-00") > -1
    ) {
      return "";
    }
    if (!value || value.indexOf("0000:") > -1 || value.indexOf("1000:") > -1) {
      return "";
    }

    const date = new Date(value);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let hours = date.getHours();
    if (hours < 10) {
      hours = "0" + hours;
    }
    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let seconds = date.getSeconds();
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    const FormattedDateTime =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
    return FormattedDateTime;
  },

  /*
    * 获取数组对象的 names 数组  getArrayObjNames 获取其中的 name 数组，用于 xAxis_data 和 legend_data
    * [
        {
            value: 60,
            name: '博士'
        },
        {
            value: 80,
            name: '硕士'
        },
        {
            value: 200,
            name: '本科'
        },
    ]
    * */
  getArrayObjNames(arrayObj) {
    const keys = [];
    for (let i = 0; i < arrayObj.length; i++) {
      keys.push(arrayObj[i].name);
    }
    return keys;
  },

  /*
    * 分组的柱状或者折线数据格式转换 getGroupData
    * json  服务端返回的 json，约定格式查看下方 JSON: 段的解释
    * seriesTpl={} / []  数据展示的模板，比如 柱状的每个柱子，折线的每个折线都是可以有不同的外观，所以要各自配置模板，
     服务端返回数据的数据格式如：
        {
            "Flag":1,
            "Msg":"OK",
            "Code":200,
            "Data":[{...},{...}...] // json 数据格式约定如下
        }
     JSON: 服务端约定 json 的数据格式：
     classify 必选，x 轴的分类方式
     data 必选，
     name 必选，
     value 必选，
     jumpUrl 自定义的参数，可以自定义多个，用于图形和服务端事件交互
     示例：
     [
        {
            classify: '煤炭开采',
            data: [
                {
                    name: '2018',
                    value: 320,
                    jumpUrl:'https://www.xxx.com/s?q=2018&classify=1'
                },
                {
                    name: '2019',
                    value: 120,
                    jumpUrl:'https://www.xxx.com/s?q=2019&classify=1'
                },
                {
                    name: '2020',
                    value: 220,
                    jumpUrl:'https://www.baidu.com/s?q=2020&classify=1'
                }
            ]
        },
        {
            classify: '煤矿支护',
            data: [
                {
                    name: '2018',
                    value: 300,
                    jumpUrl:'https://www.xxx.com/s?q=2018&classify=2'
                },
                {
                    name: '2019',
                    value: 220,
                    jumpUrl:'https://www.xxx.com/s?q=2019&classify=2'
                },
                {
                    name: '2020',
                    value: 280,
                    jumpUrl:'https://www.baidu.com/s?q=2020&classify=2'
                }
            ]
        },
    * ]
    * 转换成 echart 需要如下格式，
    * [
        {
            name: '2018',
            type: 'bar',
            emphasis: {
                focus: 'series'
            },
            data: [{jumpUrl: "xxx",value: 300},{jumpUrl: "xxx",value: 320}],
        },
        {
            name: '2019',
            type: 'bar',
            emphasis: {
                focus: 'series'
            },
            data: [{jumpUrl: "xxx",value: 380},{jumpUrl: "xxx",value: 340}],
        },
        {
            name: '2020',
            type: 'bar',
            emphasis: {
                focus: 'series'
            },
            data: [{jumpUrl: "xxx",value: 420},{jumpUrl: "xxx",value: 360}],
        }
    ];
    * */
  getGroupData(json, seriesTpl = {}) {
    const seriesTplDataType = util.isArray(seriesTpl); // seriesTpl 可以使对象或者数组
    const xAxis_data = [];
    for (const item of json) {
      xAxis_data.push(item.classify);
    }

    const legend_data = [];
    const _data_temp = [];
    for (let i = 0; i < json[0].data.length; i++) {
      const _name = json[0].data[i].name;
      legend_data.push(_name);
      const _seriesTpl = seriesTplDataType ? seriesTpl[i] : seriesTpl;
      const _series_item = util.extend(true, {}, _seriesTpl, {
        name: _name,
        data: [],
      });

      _data_temp.push(_series_item);
    }

    const _data_temp2 = [];
    for (let j = 0; j < _data_temp.length; j++) {
      const _temp = [];
      for (let k = 0; k < json.length; k++) {
        _temp.push(json[k].data[j]);
      }
      _data_temp2.push(_temp);

      _data_temp[j].data = _temp;
    }
    console.log("_data_temp", _data_temp);
    return {
      legend_data: legend_data,
      mAxis_data: xAxis_data, // 主轴 通常是 xAxis.data , 也有是有 y 轴的情况
      series: _data_temp,
    };
  },
  pieOption(config = {}) {
    return util.extend(
      true,
      {},
      {
        title: {
          text: "某站点用户访问来源",
          subtext: "纯属虚构",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        series: [
          {
            label: {
              color: "#83f352",
              fontSize: 14,
              fontWeight: 400,
              textBorderWidth: 0,
              textBorderColor: "#83f352",
              formatter: "{b}\n{c} ({d}%)",
              lineHeight: 22,
            },
            name: "访问来源",
            type: "pie",
            radius: "60%",
            center: ["50%", "50%"],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      config
    );
  },
  barOption(config = {}) {
    return util.extend(
      true,
      {},
      {
        title: {
          textAlign: "left",
          left: 18,
          top: 12,
          subtext: "",
          subtextStyle: {
            textAlign: "right",
            left: "center",
          },
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "20%",
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/> {c}",
        },
        legend: {
          textStyle: {
            color: "#83f352",
            fontSize: 12,
          },
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#83f352",
            },
          },
          data: [], // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#83f352",
            },
          },
        },
        series: [
          {
            data: [], // [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
            type: "bar",
            itemStyle: {
              emphasis: {
                show: true,
                color: "#6086fb",
              },
            },
          },
        ],
      },
      config
    );
  },
  gaugeOption(config = {}) {
    return util.extend(
      true,
      {},
      {
        series: [
          {
            title: {
              offsetCenter: [0, "82%"],
              fontSize: 18,
            },
            type: "gauge",
            min: 0,
            max: 100,
            progress: {
              show: true,
              width: 10,
            },
            axisLine: {
              lineStyle: {
                width: 10,
              },
            },
            axisTick: {
              show: true,
              distance: 0,
              length: 6,
              lineStyle: {
                color: "#666",
                width: 1,
              },
            },
            splitLine: {
              distance: 0,
              length: 12,
              lineStyle: {
                width: 1,
                color: "#666",
              },
            },
            axisLabel: {
              distance: 15,
              color: "#333",
              fontSize: 14,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 20,
              itemStyle: {
                borderWidth: 10,
              },
            },
            detail: {
              valueAnimation: true,
              fontSize: 18,
              offsetCenter: [0, "36%"],
            },
            data: [
              {
                name: "成绩评定",
                value: 70,
              },
            ],
          },
        ],
      },
      config
    );
  },
  pieNest(config = {}) {
    return util.extend(
      true,
      {},
      {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          show: false,
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          data: [], // ['专科', '本科', '研究生', '其他']
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            selectedMode: "single",
            radius: [0, "40%"],
            label: {
              position: "inner",
              fontSize: 14,
            },
            labelLine: {
              show: false,
            },
            data: [
              /* {value: 1548, name: '学士'},
                        {value: 775, name: '硕士'},
                        {value: 679, name: '博士', selected: true} */
            ],
          },
          {
            name: "访问来源",
            type: "pie",
            radius: ["52%", "68%"],
            labelLine: {
              length: 12,
            },
            label: {
              color: "#83f352",
              fontSize: 14,
              fontWeight: 400,
              textBorderWidth: 0,
              textBorderColor: "#83f352",
              formatter: "{b}\n {c}\n{d}%",
              lineHeight: 20,
            },
            data: [
              /* {value: 1048, name: '专科'},
                        {value: 335, name: '本科'},
                        {value: 310, name: '研究生'},
                        {value: 251, name: '其他'}, */
            ],
          },
        ],
      },
      config
    );
  },

  popClose() {
    this.popShow = false;
  },

  pageSearchEvent() {
    typeof this.pageSearchEventEmit !== "undefined" &&
      this.pageSearchEventEmit();
  },

  // 处理分类 级别
  getSonsTree(arrayObj, iniPid = "0") {
    console.log("arrayObj", arrayObj);
    const _array = arrayObj;
    const arrayTemp = [];
    const fn = function (id, lev) {
      for (let i = 0; i < _array.length; i++) {
        if (id === _array[i].pid) {
          _array[i].lev = lev;
          arrayTemp.push(_array[i]);
          fn(_array[i].Id, lev + 1);
        }
      }
    };
    fn(iniPid, 0);
    console.log("arrayTemp", arrayTemp);
    return arrayTemp;
  },
  unfoldData(arrayObj) {
    const array = [];
    const fn = function (arr) {
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        const chirld = item.chirld;
        delete item.chirld;
        array.push(item);
        if (chirld.length) {
          fn(chirld);
        }
      }
      // array = array.concat(tempArray)
    };
    fn(arrayObj);

    /* let array_new = []
        for (let item of array){
            array_new.push(item)
            for(let j=0;j<array.length;j++){
                let index = array_new.findIndex(value=>{
                    return value.Id === item.Id
                })
                if(!index && array[j].pid === item.Id){
                    array_new.push(array[j])
                }
            }
        } */

    return array;
  },

  onUploadPreview(file) {
    console.log("onUploadPreview", file);
  },
  onUploadRemove(file, fileList) {
    console.log("onUploadRemove", file, fileList);
  },
  // 自行实现 根据实际情况重写此方法
  onUploadSuccess(response, file, fileList) {
    console.log("onUploadSuccess", response, file, fileList);
    this.model.thumbnail = response.result;
  },

  msg(msg, type, config) {
    const typeMap = {
      true: "success",
      false: "error",
      1: "success",
      0: "error",
      "-1": "warning",
    };

    const _config = util.extend(
      {},
      {
        message: msg,
        type: typeMap[type],
      },
      config
    );

    this.$message(_config);
  },

  _confirm(msg = "提示", type = "warning", config = {}) {
    const _config = util.extend(
      {},
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: type,
      },
      config
    );

    return this.$confirm(msg, "Warning", _config)
      .then((res) => {
        return res;
      })
      .catch(() => {});
  },

  // tokenConfig->response.headers.config
  setYearQuarter(year, quarter, tokenConfig) {
    // 配置前端使用
    localStorage.setItem("defaultYear", year);
    localStorage.setItem("defaultQuarter", quarter);
    // 把配置传回给服务端
    localStorage.setItem("TOKENCONFIG", tokenConfig);
  },
  getYearQuarter(type = "") {
    const year = localStorage.getItem("defaultYear");
    const quarter = localStorage.getItem("defaultQuarter");
    console.log("getYearQuarter", year, quarter);
    if (!type) {
      return {
        year: year,
        quarter: quarter,
        yearQuarter: `${year}-${quarter}`,
      };
    }

    if (type === "y") {
      return year;
    }
    if (type === "q") {
      return quarter;
    }
    if (type === "y-q") {
      return `${year}-${quarter}`;
    }
  },

  formSubmitValidate(formName, onSuccess = null) {
    console.log("formSubmitValidate", this.$refs[formName]);
    return this.$refs[formName]
      .validate()
      .then((ok) => {
        if (!ok) {
          this.loading = false;
          return false;
        }
        onSuccess && onSuccess();
        return ok;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
  },

  jumpByPath(path = "") {
    if (!path) {
      return;
    }
    this.$router.push({ path: path });
  },

  optionsGoodsTypeFilter(prefix = "") {
    const options = [];
    const prefix_array = prefix.split(",");
    for (let i = 0; i < _OPTIONS.goodsType.length; i++) {
      const item_array = _OPTIONS.goodsType[i].split("-");
      if (prefix_array.indexOf(item_array[0]) > -1) {
        options.push(_OPTIONS.goodsType[i]);
      }
    }
    return options;
  },

  currentChange(page) {
    this.listDataConfig.params.page = page;
    this.queryData();
  },

  resetListDataConfig() {
    this.listDataConfig = {
      params: {
        pageSize: 20,
        page: 1,
        dates: [],
      },
    };
  },
  setDefaultImage(e) {
    e.target.src = defaultImage;
    console.log("ddddd");
    this.defaultImageShow = true;
  },
  isEmpty(str) {
    if (str == null || str == "" || str==undefined) {
      return true;
    }
    return false
  },
};

const _watch = {
  /* "model.unitCode": function (newVal, oldVal) {
        let obj = this._OPTIONS.unit.find(item => {
            return item.value === newVal;
        });
        if (!obj) {
            return
        }
        this.model.unitName = obj.name;
        console.log('this.model.unitName', this.model.unitName)
    } */
}

export { util, _data, _methods, _watch, _OPTIONS, _CONFIG }
